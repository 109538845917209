import { ChangeDetectorRef, Component, ElementRef, Inject, OnInit, Renderer2 } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { isEmpty, cloneDeep, isArray, sortBy } from 'lodash';
import {
	cagAlzheimersFieldKeys,
	clinicalDiagnosisFields,
	cognitiveTestsFields,
	combinedTestResultsFields,
	contactInfoFields,
	generalInfoFields,
	functionalTestsFields,
	otherInfoFields,
	cagAlzheimersFormConfigurations,
	studyIdentifierFields,
	alzheimersFormGroups,
	diagnosisFields,
} from './alzheimer.model';
import {
	APP_CONFIG,
	IAppEnvConfig,
	QNPFormConfig,
	clean,
	Field,
	HCMSLoaderControlService,
	createField,
	futureDateValidator,
	QNPFormFieldType,
	NoLeadingTrailingWhitespaceValidator,
	numberOnlyOrNumberWithCustomStringValidator,
	dateRangeValidator,
	ToastrService,
} from '@qnp/qnp-common';
import { Subscription, distinctUntilChanged, finalize, tap } from 'rxjs';
import { AlzheimersService } from '../../services/alzheimers.service';
import {
	AlzheimersFeatureResponse,
	CagGeneralInfoValidationRequest,
	CagGeneralInfoValidationResponse,
} from '../models/general-info.model';
import { NavigationExtras, Router } from '@angular/router';

@Component({
	selector: 'app-cag-alzheimer',
	templateUrl: './cag-alzheimer.component.html',
	styleUrls: ['./cag-alzheimer.component.css'],
})
export class CagAlzheimerComponent implements OnInit {
	cagForm: UntypedFormGroup;
	reCaptchaToken: string;
	showErrorBanner: boolean = false;
	isCaptchaValid: boolean = false;
	formConfigurations: any[] = cagAlzheimersFormConfigurations;
	generalInfoFormFields: QNPFormConfig = generalInfoFields;
	contactInfoFormFields: QNPFormConfig = contactInfoFields;
	contactInfoFormFieldsDeepCopy: QNPFormConfig;
	clinicalDiagnosisFormFields: QNPFormConfig = clinicalDiagnosisFields;
	clinicalDiagnosisFormFieldsDeepCopy: QNPFormConfig;
	diagnosisFormFields: QNPFormConfig = diagnosisFields;
	combinedTestResultsFormFieldsDeepCopy: QNPFormConfig;
	combinedTestResultsFormFields: QNPFormConfig = combinedTestResultsFields;
	cognitiveTestsFormFields: QNPFormConfig = cognitiveTestsFields;
	cognitiveTestsFormFieldsDeepCopy: QNPFormConfig;
	functionalTestsFormFields: QNPFormConfig = functionalTestsFields;
	functionalTestsFormFieldsDeepCopy: QNPFormConfig;
	otherInfoFormFields: QNPFormConfig = otherInfoFields;
	otherInfoFormFieldsDeepCopy: QNPFormConfig;
	studyIdentifierFormFields = studyIdentifierFields;
	dynamicFormGroups = alzheimersFormGroups;
	reCaptchaSiteKey: string;
	verifyText: 'Verify' | 'Verified' = 'Verify';
	errorText: string = '';
	isGeneraInfoValid: boolean = false;
	private dynamicSubscriptions: Subscription = new Subscription();
	private readonly cdrEnabledFields = [
		cagAlzheimersFieldKeys.Memory,
		cagAlzheimersFieldKeys.Orientation,
		cagAlzheimersFieldKeys.JudgementAndProblemSolving,
		cagAlzheimersFieldKeys.CommunityAffairs,
		cagAlzheimersFieldKeys.HomeAndHobbies,
		cagAlzheimersFieldKeys.PersonalCare,
		cagAlzheimersFieldKeys.SumOfBoxes,
		cagAlzheimersFieldKeys.Global,
		cagAlzheimersFieldKeys.CdrDate,
	];

	private readonly otherCognitiveTestFields = [
		createField(
			cagAlzheimersFieldKeys.OtherCognitiveTestScore,
			QNPFormFieldType.Text,
			'Other Cognitive Test Score',
			true,
			[
				Validators.required,
				NoLeadingTrailingWhitespaceValidator(),
				numberOnlyOrNumberWithCustomStringValidator('', true),
			]
		),
		createField(
			cagAlzheimersFieldKeys.OtherCognitiveTestDate,
			QNPFormFieldType.Date,
			'Date of Other Cognitive Test',
			true,
			[Validators.required, futureDateValidator, dateRangeValidator('01/01/1970', null)]
		),
	];
	private readonly otherFunctionalTestFields = [
		createField(
			cagAlzheimersFieldKeys.OtherFunctionalTestScore,
			QNPFormFieldType.Text,
			'Other Functional Test Score',
			true,
			[
				Validators.required,
				NoLeadingTrailingWhitespaceValidator(),
				numberOnlyOrNumberWithCustomStringValidator('', true),
			]
		),
		createField(
			cagAlzheimersFieldKeys.OtherFunctionalTestDate,
			QNPFormFieldType.Date,
			'Date of Other Functional Test',
			true,
			[Validators.required, futureDateValidator, dateRangeValidator('01/01/1970', null)]
		),
	];

	private addressListSubscription: Subscription;
	hideRecaptcha: boolean = false;

	constructor(
		public formBuilder: UntypedFormBuilder,
		private titleService: Title,
		@Inject(APP_CONFIG) private config: IAppEnvConfig,
		private alzheimersSvc: AlzheimersService,
		private toastr: ToastrService,
		private loaderControlservice: HCMSLoaderControlService,
		private cdr: ChangeDetectorRef,
		private el: ElementRef,
		private renderer: Renderer2,
		private router: Router
	) {
		this.checkFeatureInactivity();
	}

	private checkFeatureInactivity() {
		this.alzheimersSvc.showAlzheimersFeature().subscribe({
			next: ({ hideRecaptcha }: AlzheimersFeatureResponse) => {
				this.hideRecaptcha = hideRecaptcha;
			},
			error: err => {
				this.toastr.info('Alzheimers feature is inactive, please reach out to support team');
				this.router.navigate(['/cag-alzheimers']);
			},
		});
	}

	ngOnInit(): void {
		this.resetDynamicFormGroups();
		this.initializeFormFieldsCopy();
		this.reCaptchaSiteKey = this.config.reCaptchaSiteKey;
		this.titleService.setTitle("Alzheimer's form");
		this.initializeCagFormGroup();
	}

	private initializeFormFieldsCopy(): void {
		this.combinedTestResultsFormFieldsDeepCopy = cloneDeep(this.combinedTestResultsFormFields);
		this.clinicalDiagnosisFormFieldsDeepCopy = cloneDeep(this.clinicalDiagnosisFormFields);
		this.cognitiveTestsFormFieldsDeepCopy = cloneDeep(this.cognitiveTestsFormFields);
		this.functionalTestsFormFieldsDeepCopy = cloneDeep(this.functionalTestsFormFields);
		this.contactInfoFormFieldsDeepCopy = cloneDeep(this.contactInfoFormFields);
		this.otherInfoFormFieldsDeepCopy = cloneDeep(this.otherInfoFormFields);
	}

	private initializeCagFormGroup(): void {
		this.cagForm = this.formBuilder.group({
			generalInfo: new UntypedFormGroup({}),
		});
	}

	private removeDynamicFormGroups(): void {
		for (const key of Object.keys(this.dynamicFormGroups)) {
			this.cagForm.removeControl(key);
		}
	}

	private resetFormFields(): void {
		this.combinedTestResultsFormFields = cloneDeep(this.combinedTestResultsFormFieldsDeepCopy);
		this.clinicalDiagnosisFormFields = cloneDeep(this.clinicalDiagnosisFormFieldsDeepCopy);
		this.cognitiveTestsFormFields = cloneDeep(this.cognitiveTestsFormFieldsDeepCopy);
		this.functionalTestsFormFields = cloneDeep(this.functionalTestsFormFieldsDeepCopy);
		this.contactInfoFormFields = cloneDeep(this.contactInfoFormFieldsDeepCopy);
		this.otherInfoFormFields = cloneDeep(this.otherInfoFormFieldsDeepCopy);
	}

	private setCombinedTestResultsFormFields() {
		const disabledDateFields = [
			cagAlzheimersFieldKeys.Memory,
			cagAlzheimersFieldKeys.Orientation,
			cagAlzheimersFieldKeys.JudgementAndProblemSolving,
			cagAlzheimersFieldKeys.CommunityAffairs,
			cagAlzheimersFieldKeys.HomeAndHobbies,
			cagAlzheimersFieldKeys.PersonalCare,
			cagAlzheimersFieldKeys.SumOfBoxes,
			cagAlzheimersFieldKeys.Global,
			cagAlzheimersFieldKeys.CdrDate,
		];
		const deepCopy = cloneDeep(this.combinedTestResultsFormFieldsDeepCopy);
		const reducedFields = deepCopy[0].fields.filter((field: Field) => {
			return !disabledDateFields.includes(field.key as cagAlzheimersFieldKeys);
		});
		this.combinedTestResultsFormFields = [
			{
				heading: this.combinedTestResultsFormFieldsDeepCopy[0]?.heading,
				helpText: this.combinedTestResultsFormFieldsDeepCopy[0]?.helpText,
				fields: [...reducedFields],
			},
		];
	}

	private setClinicalDiagnosisFormFields() {
		const disabledDateFields = [
			cagAlzheimersFieldKeys.AmyloidPETScanDate,
			cagAlzheimersFieldKeys.CSFTestResultDate,
			cagAlzheimersFieldKeys.OtherAmyloidTestDate,
			cagAlzheimersFieldKeys.OtherAmyloidTestType,
		];
		const deepCopy = cloneDeep(this.clinicalDiagnosisFormFieldsDeepCopy);
		const reducedFields = deepCopy[0].fields.filter((field: Field) => {
			return !disabledDateFields.includes(field.key as cagAlzheimersFieldKeys);
		});
		this.clinicalDiagnosisFormFields = [
			{
				heading: this.clinicalDiagnosisFormFieldsDeepCopy[0]?.heading,
				formId: this.clinicalDiagnosisFormFieldsDeepCopy[0]?.formId,
				helpText: this.clinicalDiagnosisFormFieldsDeepCopy[0]?.helpText,
				fields: [...reducedFields],
			},
		];
	}

	private setOtherInfoFormFields() {
		const disabledDateFields = [
			cagAlzheimersFieldKeys.AriaEEvidenceDate,
			cagAlzheimersFieldKeys.AriaHEvidenceDate,
		];
		const deepCopy = cloneDeep(this.otherInfoFormFieldsDeepCopy);
		const reducedFields = deepCopy[0].fields.filter((field: Field) => {
			return !disabledDateFields.includes(field.key as cagAlzheimersFieldKeys);
		});
		this.otherInfoFormFields = [
			{
				heading: this.otherInfoFormFieldsDeepCopy[0]?.heading,
				helpText: this.otherInfoFormFieldsDeepCopy[0]?.helpText,
				fields: [...reducedFields],
			},
		];
	}

	private listenForFormChanges(formKey: string, fieldKeys: string[]) {
		const formGroup = this.cagForm.get(formKey) as UntypedFormGroup;
		fieldKeys.forEach(fieldKey => {

			const subscription = formGroup
				.get(fieldKey)
				.valueChanges.pipe(

          distinctUntilChanged())
				.subscribe((value: string) => {
					if (isEmpty(value)) return;
					const dateFieldKey = `${fieldKey}Date`;
					if (
						value !== 'Not Performed' &&
						value !== 'No' &&
						!formGroup.contains(dateFieldKey) &&
						formGroup.get(fieldKey)?.valid
					) {
						const dateField = this[`${formKey}FormFieldsDeepCopy`][0].fields.find(
							(Field: Field) => Field.key === dateFieldKey
						);
						const fieldsToAdd = [dateField];
						if (fieldKey === cagAlzheimersFieldKeys.OtherAmyloidTest) {
							const otherAmyloidTestField = this[`${formKey}FormFieldsDeepCopy`][0].fields.find(
								(Field: Field) => Field.key === cagAlzheimersFieldKeys.OtherAmyloidTestType
							);
							fieldsToAdd.push(otherAmyloidTestField);
						}
						const reducedFields = this.addElementsAfter(
							this[`${formKey}FormFields`][0].fields,
							fieldKey,
							fieldsToAdd
						);
						this[`${formKey}FormFields`] = [
							{
								heading: this[`${formKey}FormFieldsDeepCopy`][0]?.heading,
								helpText: this[`${formKey}FormFieldsDeepCopy`][0]?.helpText,
								formId: this[`${formKey}FormFieldsDeepCopy`][0]?.formId,
								fields: [...reducedFields],
							},
						];
					} else if (
						(value === 'Not Performed' || value === 'No') &&
						formGroup.contains(dateFieldKey)
					) {
						formGroup.removeControl(dateFieldKey);
						const isAmoloiydTest =
							formGroup.contains(cagAlzheimersFieldKeys.OtherAmyloidTestType) &&
							fieldKey === cagAlzheimersFieldKeys.OtherAmyloidTest;
						if (isAmoloiydTest) {
							formGroup.removeControl(cagAlzheimersFieldKeys.OtherAmyloidTestType);
						}
						const reducedFields = this[`${formKey}FormFields`][0].fields.filter((field: Field) => {
							if (isAmoloiydTest) {
								return (
									field.key !== dateFieldKey &&
									field.key !== cagAlzheimersFieldKeys.OtherAmyloidTestType
								);
							}
							return field.key !== dateFieldKey;
						});
						this[`${formKey}FormFields`] = [
							{
								heading: this[`${formKey}FormFieldsDeepCopy`][0]?.heading,
								helpText: this[`${formKey}FormFieldsDeepCopy`][0]?.helpText,
								formId: this[`${formKey}FormFieldsDeepCopy`][0]?.formId,
								fields: [...reducedFields],
							},
						];
					}
					setTimeout(() => {
						formGroup.markAllAsTouched();
					});
				});
			this.cagForm.updateValueAndValidity();
			this.dynamicSubscriptions.add(subscription);
		});
	}

	private addElementsAfter(
		array: Array<Field>,
		givenKey: string,
		newElements: Array<Field>
	): Array<Field> {
		for (let i = 0; i < array.length; i++) {
			if (array[i].key === givenKey) {
				array.splice(i + 1, 0, ...newElements);
				break;
			}
		}
		return array;
	}

	submitAlzheimersForm() {
		this.cognitiveTestsFormFields[0].errorMessages = [];
		this.functionalTestsFormFields[0].errorMessages = [];
		if (!this.IsValidPostSubmmmitedData()) {
			return;
		}
		this.loaderControlservice.setLoaderStatus(true);
		const subscription = this.alzheimersSvc
			.submitAlzeihmerForm(this.buildRequestPayload())
			.pipe(finalize(() => this.loaderControlservice.setLoaderStatus(false)))
			.subscribe({
				next: (data: any) => {
					const navigationExtras: NavigationExtras = {
						state: {
							submissionRequest:
								data.cagRequestNumber ??
								'unable to get request number at this moment,please contact help desk',
						},
					};
					this.router.navigate(['alzheimers-ced-registry/submission-success'], navigationExtras);
					this.cleanupCagForm();
				},
				error: err => {
					this.showErrorBanner = true;
				},
			});
		this.dynamicSubscriptions.add(subscription);
	}

	private buildRequestPayload() {
		const {
			studyIdentifier,
			generalInfo,
			contactInfo,
			diagnosis,
			clinicalDiagnosis,
			otherInfo,
			cognitiveTests,
			functionalTests,
			combinedTestResults,
		} = this.cagForm.getRawValue();

		const { submitterEmail, submitterAddressList, ...restContactInfo } = contactInfo;

		// Check if combinedTestResults.CdrTestPerformed is not null, if it's null do not include it in the object
		const includeCombinedTestResults = combinedTestResults.CdrTestPerformed !== null;

		const requestPayload = {
			generalInfo: {
				...generalInfo,
				nctNumber: studyIdentifier.nctNumber,
				recaptchaToken: this.hideRecaptcha ? '1234zText recaptcha token' : this.reCaptchaToken,
			},
			contactInfo: submitterEmail ? { ...restContactInfo, submitterEmail } : { ...restContactInfo },
			clinicalDiagnosis: {
				...diagnosis,
				...clinicalDiagnosis,
				...(includeCombinedTestResults && { combinedTestResults }),
				individualTestResults: {
					cognitiveTests,
					functionalTests,
				},
			},
			otherInfo,
		};

		return clean(requestPayload, ['', null]);
	}

	private IsValidPostSubmmmitedData(): boolean {
		const { cognitiveTests, functionalTests, clinicalDiagnosis } = this.cagForm.getRawValue();
		const noCognitiveTestsPerformed = Object.values(cognitiveTests).every(val => isEmpty(val));
		const noFunctionalTestsPerformed = Object.values(functionalTests).every(val => isEmpty(val));
		const clinicalDiagnosisInValid =
			clinicalDiagnosis.amyloidPETScan === 'Not Performed' &&
			clinicalDiagnosis.csfTestResult === 'Not Performed' &&
			clinicalDiagnosis.otherAmyloidTest === 'Not Performed';
		if (clinicalDiagnosisInValid) {
			this.clinicalDiagnosisFormFields[0].errorMessages = [
				'One of the above tests is required for form submission. Choose an option other than Not Performed from the dropdown.',
			];
		}
		if (noCognitiveTestsPerformed) {
			this.cognitiveTestsFormFields[0].errorMessages = [
				'One of the above Cognitive tests is required for form submission',
			];
		}
		if (noFunctionalTestsPerformed) {
			this.functionalTestsFormFields[0].errorMessages = [
				'One of the above Functional tests is required for form submission',
			];
		}
		const isCognitiveDateFieldEmpty =
			!isEmpty(cognitiveTests.mocaScore) && isEmpty(cognitiveTests.mocaScoreDate);
		if (isCognitiveDateFieldEmpty) {
			this.cognitiveTestsFormFields[0].errorMessages = [
				'Date of MoCA is required for form submission',
			];
		}

		const isCognitiveFieldEmpty =
			isEmpty(cognitiveTests.mocaScore) && !isEmpty(cognitiveTests.mocaScoreDate);
		if (isCognitiveFieldEmpty) {
			this.cognitiveTestsFormFields[0].errorMessages = [
				'MoCA Score is required for form submission',
			];
		}
		const isFunctionalDateFieldEmpty =
			!isEmpty(functionalTests.faqScore) && isEmpty(functionalTests.faqScoreDate);
		const isFunctionalFieldEmpty =
			isEmpty(functionalTests.faqScore) && !isEmpty(functionalTests.faqScoreDate);

		if (isFunctionalDateFieldEmpty) {
			this.functionalTestsFormFields[0].errorMessages = [
				'Date of FAQ is required for form submission',
			];
		}
		if (isFunctionalFieldEmpty) {
			this.functionalTestsFormFields[0].errorMessages = [
				'FAQ Score is required for form submission',
			];
		}

		if (clinicalDiagnosisInValid) {
			this.scrollToElement(this.clinicalDiagnosisFormFields[0].formId);
			return false;
		}

		if (noCognitiveTestsPerformed && noFunctionalTestsPerformed) {
			this.scrollToElement(this.cognitiveTestsFormFields[0].formId);
			return false;
		}
		if (isCognitiveDateFieldEmpty) {
			this.scrollToElement(this.cognitiveTestsFormFields[0].formId);
			return false;
		}
		if (isCognitiveFieldEmpty) {
			this.scrollToElement(this.cognitiveTestsFormFields[0].formId);
			return false;
		}

		if (noCognitiveTestsPerformed) {
			this.scrollToElement(this.cognitiveTestsFormFields[0].formId);
			return false;
		}
		if (isFunctionalDateFieldEmpty) {
			this.scrollToElement(this.functionalTestsFormFields[0].formId);
			return false;
		}
		if (isFunctionalFieldEmpty) {
			this.scrollToElement(this.functionalTestsFormFields[0].formId);
			return false;
		}

		if (noFunctionalTestsPerformed) {
			this.scrollToElement(this.functionalTestsFormFields[0].formId);
			return false;
		}
		return true;
	}

	scrollToElement(elementId: string): void {
		const element = this.el.nativeElement.querySelector(`#${elementId}`);
		if (element) {
			element.scrollIntoView({ behavior: 'smooth', block: 'start' });
		}
	}

	// Method invoked after the reCaptcha puzzel is solved.
	// We store the reCaptcha Token in the ProfileInfo form object.
	resolved(captchaResponse: string) {
		this.isCaptchaValid = !isEmpty(captchaResponse);
		this.reCaptchaToken = captchaResponse;
		this.cagForm.updateValueAndValidity();
	}

	cleanupCagForm(): void {
		this.verifyText = 'Verify';
		this.isCaptchaValid = false;
		this.showErrorBanner = false;
		this.destroySubscriptions();
		this.cagForm.get('generalInfo').enable();
		this.resetCagForm();
		this.removeDynamicFormGroups();
		this.resetFormFields();
		this.resetDynamicFormGroups();
	}

	private resetCagForm() {
		Object.keys(this.cagForm.controls).forEach(key => {
			const control = this.cagForm.get(key);
			if (control && control.enabled) {
				if (control instanceof UntypedFormGroup) {
					this.resetCAGFormControls(control);
				} else {
					control.reset();
				}
			}
		});
	}
	private resetCAGFormControls(formGroup: UntypedFormGroup) {
		Object.keys(formGroup.controls).forEach(key => {
			const control = formGroup.get(key);
			if (control && control.enabled) {
				if (control instanceof UntypedFormGroup) {
					this.resetCAGFormControls(control);
				} else {
					control.reset();
				}
			}
		});
	}

	private resetDynamicFormGroups() {
		const dynamicFormGroups = {};
		for (const key in this.dynamicFormGroups) {
			dynamicFormGroups[key] = new UntypedFormGroup({});
		}
		this.dynamicFormGroups = Object.assign({}, dynamicFormGroups);
	}

	verifyGeneralInfo(): void {
		this.loaderControlservice.setLoaderStatus(true);

		const { npi, mbi } = this.cagForm.value.generalInfo;
		const requestPayload: CagGeneralInfoValidationRequest = {
			npi: npi ?? '',
			mbi: mbi ?? '',
		};

		this.alzheimersSvc
			.verifyGeneralInfo(requestPayload)
			.pipe(finalize(() => this.loaderControlservice.setLoaderStatus(false)))
			.subscribe({
				next: response => this.handleResponse(response),
				error: err => this.handleError(err),
			});
	}

	private handleResponse({ mbi, provider }: CagGeneralInfoValidationResponse) {
		if (this.dynamicSubscriptions?.closed) {
			this.dynamicSubscriptions = new Subscription();
		}
		this.toggleAlzheimersFormDisplay(mbi, provider);
		this.updateErrorAlertMessages(mbi, provider);
	}

	private toggleAlzheimersFormDisplay(mbi, provider) {
		if (mbi.isValid && provider.isValid) {
			this.cagForm.get('generalInfo').disable();
			this.verifyText = 'Verified';
			this.setDyamanicFormFields();
			this.addDynamicFormGroups();
			this.updateContactInfoForm(mbi, provider);
			setTimeout(() => {
				this.listenForClinicalDiagnosisForm();
				this.listenForClinicalDiagnosisField();
				this.listenForOtherInfoForm();
				this.listenForCombinedTestResultsForm();
				this.listenForOtherTestFieldChange(
					this.cagForm.get('cognitiveTests') as UntypedFormGroup,
					cagAlzheimersFieldKeys.OtherCognitiveTest
				);
				this.listenForOtherTestFieldChange(
					this.cagForm.get('functionalTests') as UntypedFormGroup,
					cagAlzheimersFieldKeys.OtherFunctionalTest
				);
				this.cagForm.updateValueAndValidity();
				this.cagForm.markAllAsTouched();
			}, 0);
		} else {
			this.cleanupCagForm();
		}
	}
	private updateErrorAlertMessages(mbi, provider) {
		this.isGeneraInfoValid = !mbi.isValid || !provider.isValid;

		if (!mbi.isValid && !provider.isValid) {
			this.errorText =
				"Both the MBI and NPI are invalid. Please make sure you've entered them correctly to proceed further.";
		} else if (!mbi.isValid) {
			this.errorText = 'The MBI provided is invalid.';
		} else if (!provider.isValid) {
			this.errorText = 'The NPI provided is invalid. Please enter a valid NPI.';
		}
	}

	private setDyamanicFormFields(): void {
		this.setCombinedTestResultsFormFields();
		this.setClinicalDiagnosisFormFields();
		this.setOtherInfoFormFields();
	}

	private listenForClinicalDiagnosisForm(): void {
		this.listenForFormChanges('clinicalDiagnosis', [
			cagAlzheimersFieldKeys.AmyloidPETScan,
			cagAlzheimersFieldKeys.CSFTestResult,
			cagAlzheimersFieldKeys.OtherAmyloidTest,
		]);
	}
	private listenForClinicalDiagnosisField() {

		const subscription = this.cagForm
			.get('diagnosis')
			.get(cagAlzheimersFieldKeys.ClinicalDiagnosisType)
			.valueChanges.pipe(

        distinctUntilChanged())
			.subscribe(value => {
				const helptext =
					value === 'MCI Due to AD'
						? "Mild Cognitive Impairment due to Alzheimer's Disease (MCI due to AD)"
						: "Mild Alzheimer's Disease Dementia (Mild AD Dementia)";
				this.diagnosisFormFields[0].fields.forEach((field: Field) => {
					if (field.key === cagAlzheimersFieldKeys.ClinicalDiagnosisType) {
						field.templateOptions.helpText = helptext;
					}
				});
			});
		this.dynamicSubscriptions.add(subscription);
	}

	private listenForOtherInfoForm(): void {
		this.listenForFormChanges('otherInfo', [
			cagAlzheimersFieldKeys.AriaEEvidence,
			cagAlzheimersFieldKeys.AriaHEvidence,
		]);
	}

	private addDynamicFormGroups(): void {
		for (const [key, group] of Object.entries(this.dynamicFormGroups)) {
			this.cagForm.addControl(key, group);
		}
	}

	private updateContactInfoForm(mbi, provider) {
		const contactInfoForm = this.cagForm.get('contactInfo') as UntypedFormGroup;

		if (isArray(provider?.address) && !isEmpty(provider?.address)) {
			this.addressHandling(contactInfoForm, provider);
		} else {
			if (contactInfoForm.contains(cagAlzheimersFieldKeys.SubmitterAddressList)) {
				this.removeAddressListControl(contactInfoForm);
			}
			contactInfoForm.reset();
		}
	}

	private addressHandling(contactInfoForm: UntypedFormGroup, provider) {
		provider!.address = provider.address.map((address: any) =>
			Object.entries(address).reduce((newAddress: any, [key, value]) => {
				newAddress[key] = typeof value === 'string' && value?.length > 0 ? value.trim() : value;
				return newAddress;
			}, {})
		);
		if (this.addressListSubscription) {
			this.addressListSubscription.unsubscribe();
		}

		this.handleAddressList(contactInfoForm, provider?.address);

		if (provider?.address?.length == 1) {
			contactInfoForm.patchValue({ ...provider?.address[0] });
			return;
		}

		this.addSubmitterAddressListField(contactInfoForm, provider?.address);
	}

	private handleAddressList(contactInfoForm: UntypedFormGroup, addressList) {
		const dropDownOptions = addressList.map(address => {
			const addressString = `${address.submitterAddress}, ${address.submitterCity}, ${address.submitterStateCode}, ${address.submitterZipCode}`;
			return {
				name: addressString,
				value: addressString,
			};
		});

		if (contactInfoForm.contains(cagAlzheimersFieldKeys.SubmitterAddressList)) {
			this.removeAddressListControl(contactInfoForm);
		}

		return dropDownOptions;
	}

	private removeAddressListControl(contactInfoForm: UntypedFormGroup) {
		contactInfoForm.removeControl(cagAlzheimersFieldKeys.SubmitterAddressList);
		this.contactInfoFormFields[0].fields = this.contactInfoFormFields[0].fields.filter(
			(field: Field) => {
				return field.key !== cagAlzheimersFieldKeys.SubmitterAddressList;
			}
		);
	}

	private addSubmitterAddressListField(contactInfoForm: UntypedFormGroup, addressList) {
		addressList = sortBy(addressList, ['submitterAddress']);
		const dropDownOptions = this.handleAddressList(contactInfoForm, addressList);
		const AddressListField = createField(
			cagAlzheimersFieldKeys.SubmitterAddressList,
			'drop-down',
			'Submitter Address List',
			false,
			[],
			{
				options: dropDownOptions,
				defaultValue: dropDownOptions[0]?.value,
				helpText: 'Select an address or manually enter if not listed.',
			}
		);
		const defaultAdddress = addressList[0];
		this.contactInfoFormFields = [
			{
				heading: this.contactInfoFormFields[0]?.heading,
				fields: [AddressListField, ...this.contactInfoFormFields[0].fields],
			},
		];
		this.subscribeToAddressChanges(contactInfoForm, defaultAdddress);
	}

	private subscribeToAddressChanges(contactInfoForm: UntypedFormGroup, defaultAdddress) {
		setTimeout(() => {
			contactInfoForm.patchValue({ ...defaultAdddress });
			this.addressListSubscription = contactInfoForm
				.get(cagAlzheimersFieldKeys.SubmitterAddressList)
				?.valueChanges.subscribe(value => {
					if (isEmpty(value)) return;
					const [submitterAddress, submitterCity, submitterStateCode, submitterZipCode] =
						value.split(', ');
					contactInfoForm.patchValue({
						submitterAddress,
						submitterCity,
						submitterStateCode,
						submitterZipCode,
					});
				});
		}, 0);
	}

	private listenForCombinedTestResultsForm() {
		const combinedTestResultsGroup = this.cagForm.get('combinedTestResults') as UntypedFormGroup;

		const Subscription = combinedTestResultsGroup.valueChanges.subscribe(formValues => {
			const cognitiveAbilityFields = [
				cagAlzheimersFieldKeys.Memory,
				cagAlzheimersFieldKeys.Orientation,
				cagAlzheimersFieldKeys.JudgementAndProblemSolving,
				cagAlzheimersFieldKeys.CommunityAffairs,
				cagAlzheimersFieldKeys.HomeAndHobbies,
				cagAlzheimersFieldKeys.PersonalCare,
			];
			const hasKeysInCognitiveAbilityFields = cognitiveAbilityFields.every(field =>
				formValues.hasOwnProperty(field)
			);
			if (hasKeysInCognitiveAbilityFields) {
				const total = cognitiveAbilityFields
					.map(field => formValues[field] ?? 0)
					.reduce((acc, val) => acc + Number(val), 0);

				if (!isNaN(total)) {
					this.cagForm
						.get('combinedTestResults')
						.get(cagAlzheimersFieldKeys.SumOfBoxes)
						?.setValue(String(total), { emitEvent: false });
				}
			}
		});
		this.dynamicSubscriptions.add(Subscription);
		setTimeout(() => {
			this.listenForCdrTestPerformedChanges(combinedTestResultsGroup);
		}, 0);
	}

	private listenForOtherTestFieldChange(testFormGroup: UntypedFormGroup, formKey): void {
		const otherTestControl = testFormGroup.get(formKey);

		if (!otherTestControl) {
			return;
		}

		const subscription = otherTestControl.valueChanges.subscribe(value => {
			if (this.shouldAddOtherTestFieldsToFormGroup(testFormGroup, formKey)) {
				this.addOtherTestFieldsToFormGroup(formKey);
				this.addFocusAndBlurEventsToOtherTestField(formKey);
			}
		});

		this.dynamicSubscriptions.add(subscription);
	}

	private shouldAddOtherTestFieldsToFormGroup(testFormGroup: UntypedFormGroup, formKey): boolean {
		return !testFormGroup.contains(`${formKey}Date`) && testFormGroup.get(formKey)?.valid;
	}

	private addOtherTestFieldsToFormGroup(formKey): void {
		const otherTestFields =
			formKey === cagAlzheimersFieldKeys.OtherCognitiveTest
				? this.otherCognitiveTestFields
				: this.otherFunctionalTestFields;

		if (formKey === cagAlzheimersFieldKeys.OtherCognitiveTest) {
			this.cognitiveTestsFormFields = [
				{
					heading: this.cognitiveTestsFormFieldsDeepCopy[0]?.heading,
					fields: [...this.cognitiveTestsFormFields[0].fields, ...otherTestFields],
				},
			];
		} else if (formKey === cagAlzheimersFieldKeys.OtherFunctionalTest) {
			this.functionalTestsFormFields = [
				{
					heading: this.functionalTestsFormFieldsDeepCopy[0]?.heading,
					fields: [...this.functionalTestsFormFields[0].fields, ...otherTestFields],
				},
			];
		}
	}

	private addFocusAndBlurEventsToOtherTestField(formKey): void {
		setTimeout(() => {
			const inputElement = this.el.nativeElement.querySelector(`#${formKey}`);

			if (inputElement) {
				this.renderer.selectRootElement(inputElement).focus();
				this.renderer.listen(inputElement, 'blur', event =>
					this.onOtherTestFieldBlur(event, formKey)
				);
			}
		}, 0);
	}

	private onOtherTestFieldBlur(event, formKey): void {
		if (isEmpty(event.target.value)) {
			this.removeUnusedOtherTestFields(formKey);
		}
	}

	private removeUnusedOtherTestFields(formKey): void {
		const dynamicCognitivetestFields = [
			cagAlzheimersFieldKeys.OtherCognitiveTestScore,
			cagAlzheimersFieldKeys.OtherCognitiveTestDate,
		];
		const dynamicFunctionalFields = [
			cagAlzheimersFieldKeys.OtherFunctionalTestScore,
			cagAlzheimersFieldKeys.OtherFunctionalTestDate,
		];

		const testFieldsToRemove =
			formKey === cagAlzheimersFieldKeys.OtherCognitiveTest
				? dynamicCognitivetestFields
				: dynamicFunctionalFields;

		const formField =
			formKey === cagAlzheimersFieldKeys.OtherCognitiveTest
				? this.cognitiveTestsFormFields
				: this.functionalTestsFormFields;

		const deepCopyField =
			formKey === cagAlzheimersFieldKeys.OtherCognitiveTest
				? this.cognitiveTestsFormFieldsDeepCopy
				: this.functionalTestsFormFieldsDeepCopy;

		const formGroupName =
			formKey === cagAlzheimersFieldKeys.OtherCognitiveTest ? 'cognitiveTests' : 'functionalTests';

		const reducedFields = formField[0].fields.filter(
			(field: Field) => !testFieldsToRemove.includes(field.key as cagAlzheimersFieldKeys)
		);

		formField[0] = {
			heading: deepCopyField[0]?.heading,
			fields: [...reducedFields],
		};

		const testFormGroup = this.cagForm.get(formGroupName) as UntypedFormGroup;
		testFieldsToRemove.forEach(field => {
			testFormGroup.removeControl(field);
		});
	}

	private listenForCdrTestPerformedChanges(combinedTestResultsGroup: UntypedFormGroup) {
		const subscription = combinedTestResultsGroup
			.get(cagAlzheimersFieldKeys.CdrTestPerformed)
			?.valueChanges.subscribe(value => {
				if (value === 'Yes') {
					this.updateCombinedResultsFormFields(combinedTestResultsGroup, this.cdrEnabledFields);
				} else if (value === 'No') {
					this.removeCombinedResultsFormFields(combinedTestResultsGroup, this.cdrEnabledFields);
				}
				combinedTestResultsGroup.updateValueAndValidity();
				this.cdr.detectChanges();
			});
		this.dynamicSubscriptions.add(subscription);
	}

	private updateCombinedResultsFormFields(combinedTestResultsGroup, enabledFields) {
		const originalFields = cloneDeep(this.combinedTestResultsFormFieldsDeepCopy);
		const newFields = this.filterFields(originalFields[0].fields, enabledFields);
		const CdrTestPerformedField = this.getDefaultField(
			this.combinedTestResultsFormFields[0].fields
		);
		this.combinedTestResultsFormFields = [
			{
				heading: this.combinedTestResultsFormFieldsDeepCopy[0]?.heading,
				fields: [CdrTestPerformedField, ...newFields],
			},
		];
		setTimeout(() => {
			combinedTestResultsGroup.markAllAsTouched();
		});
	}

	private removeCombinedResultsFormFields(combinedTestResultsGroup, fieldsToRemove) {
		if (Object.keys(combinedTestResultsGroup.value)?.length > 1) {
			this.removeFormControls(combinedTestResultsGroup, fieldsToRemove);
		}
		const CdrTestPerformedField = this.getDefaultField(
			this.combinedTestResultsFormFields[0].fields
		);
		this.combinedTestResultsFormFields = [
			{
				heading: this.combinedTestResultsFormFieldsDeepCopy[0]?.heading,
				fields: [CdrTestPerformedField],
			},
		];
	}

	private removeFormControls(formGroup, fields) {
		fields.forEach(field => {
			formGroup.removeControl(field);
		});
	}

	private filterFields(fields, fieldKeys) {
		return fields.filter((field: Field) => {
			return fieldKeys.includes(field.key as cagAlzheimersFieldKeys);
		});
	}

	private getDefaultField(fields) {
		return fields.find(field => field.key === cagAlzheimersFieldKeys.CdrTestPerformed);
	}

	private handleError(err) {
		this.cleanupCagForm();
		if (err.status == 400) {
			this.toastr.error('The MBI/NPI provided is invalid. Please enter a valid MBI.');
		} else {
			this.showErrorBanner = true;
		}
	}

	private destroySubscriptions() {
		this.dynamicSubscriptions && this.dynamicSubscriptions.unsubscribe();
	}
}
