import { Injectable } from '@angular/core';

declare let _paq: any;

@Injectable({
  providedIn: 'root'
})
export class MatomoService {

  constructor() { }

  trackPageView(customUrl?: string, customTitle?: string) {
    if (customUrl) {
      _paq.push(['setCustomUrl', customUrl]);
    }
    if (customTitle) {
      _paq.push(['setDocumentTitle', customTitle]);
    }
    _paq.push(['trackPageView']);
  }

  deleteCustomVariables(scope: string) {
    _paq.push(['deleteCustomVariables', scope]);
  }

  deleteCustomDimension(index: number) {
    _paq.push(['deleteCustomDimension', index]);
  }

  setReferrerUrl(url: string) {
    _paq.push(['setReferrerUrl', url]);
  }
  setCustomDimension(index: number, value: string) {
    _paq.push(['setCustomDimension', index, value]);
  }


  enableLinkTracking() {
    _paq.push(['enableLinkTracking']);
  }

  scanForMedia(element: any) {
    _paq.push(['MediaAnalytics::scanForMedia', element]);
  }

  scanForForms(element: any) {
    _paq.push(['FormAnalytics::scanForForms', element]);
  }

  trackContentImpressionsWithinNode(element: any) {
    _paq.push(['trackContentImpressionsWithinNode', element]);
  }
}
