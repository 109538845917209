import { Observable } from 'rxjs';
import { Component, OnInit, Inject } from '@angular/core';
import {
	APP_CONFIG,
	HCMSLoaderControlService,
	IAppEnvConfig,
	TealiumUtagService,
} from '@qnp/qnp-common';
import { MatomoService } from './matomo.service';
import { NavigationEnd, Router } from '@angular/router';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
	title = 'ess-hcms-public-ui';
	isLoading$: Observable<boolean>;
    baseURL = window.location.origin;
	constructor(
		@Inject(APP_CONFIG) private config: IAppEnvConfig,
		private loaderControlservice: HCMSLoaderControlService,
		private tealium: TealiumUtagService,
		private router: Router,
		private matomoService: MatomoService
	  ) {
		this.initializeTealium();
	  }
	
	  ngOnInit() {
		this.isLoading$ = this.loaderControlservice.getLoaderStatus() as any;
		this.router.events.subscribe(event => {
		  if (event instanceof NavigationEnd) {
			this.trackPage(event.urlAfterRedirects);
		  }
		});
	  }
	
	  private initializeTealium() {
		this.tealium.setConfig({
		  account: 'cmsgov',
		  profile: 'cms-general',
		  environment: this.config.gaEnv,
		});
		this.trackTealiumView();
	  }
	
	  private trackTealiumView() {
		this.tealium.view({
		  site_name: 'QualityNet',
		  language: 'en',
		  environment: this.config.gaEnv,
		  site_environment: this.config.gaEnv,
		  page_path: window.location.href,
		  tealium_event: 'page_view',
		  eVar26: this.baseURL,
		  content_language: 'en',
		});
	  }
	
	  private setMatomoDimensions() {
		this.matomoService.setCustomDimension(1, 'QualityNet');
		this.matomoService.setCustomDimension(2, 'en');
		this.matomoService.setCustomDimension(3, this.config.gaEnv);
		this.matomoService.setCustomDimension(4, window.location.href);
		this.matomoService.setCustomDimension(5, 'page_view');
		this.matomoService.setCustomDimension(6, this.baseURL);
		this.matomoService.setCustomDimension(7, 'en');
	  }
	
	  private trackPage(customUrl: string) {
		this.setMatomoDimensions();
		this.matomoService.trackPageView(customUrl);
	  }
}