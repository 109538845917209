import { isEmpty } from 'lodash';
import { Component, OnInit } from '@angular/core';
import {
	UntypedFormBuilder,
	UntypedFormGroup,
	Validators,
	UntypedFormControl,
} from '@angular/forms';
import { PageService } from '../../../services/page.service';
import { Router, NavigationExtras } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { zip } from 'rxjs';
import { PagerService, SecurityValidator, ToastrService } from '@qnp/qnp-common';

@Component({
	selector: 'app-listserv-signup',
	templateUrl: './listserv-signup.component.html',
	styleUrls: ['./listserv-signup.component.css'],
})
export class SignupComponent implements OnInit {
	signupForm: UntypedFormGroup = this.formBuilder.group({
		name: [
			'',
			[
				Validators.required,
				Validators.pattern('(?! )[^\r\n\t\f\v]*[^ ]'),
				SecurityValidator.notCsv,
			],
		],
		email: ['', [Validators.required, Validators.email, SecurityValidator.notCsv]],
	});
	searchForm: UntypedFormGroup = this.formBuilder.group({
		search: [
			'',
			[Validators.required, Validators.pattern(/^[-._A-Za-z0-9\s]+$/), SecurityValidator.notCsv],
		],
	});
	privateLists = [];
	publicLists = [];
	lists = { totalCount: 0 };
	isLoading: boolean = false;
	listTags: any;
	pager: any = {};
	pagedItems: any[];
	privatePage: boolean = false;
	publicSearchTerm: string = '';
	privateSearchTerm: string = '';
	constructor(
		private formBuilder: UntypedFormBuilder,
		private toastr: ToastrService,
		private publicContentService: PageService,
		private router: Router,
		private titleService: Title,
		private pagerService: PagerService
	) {}
	ngOnInit() {
		this.titleService.setTitle('Sign Up for Email Updates');
		this.getListsWithTags();
	}

	public isListFoundWithTag({ tags }, currentTag): boolean {
		return tags.find(tag => tag.id === currentTag.id);
	}

	private trimWhiteSpaces(searchTerm) {
		let trimmedSearchTerm = searchTerm;
		if (trimmedSearchTerm) {
			return trimmedSearchTerm.trim();
		}
		return trimmedSearchTerm;
	}

	private handleListsResponse(lists, tags, type) {
		const dummyList = { id: 'noId', tag_name: 'Other', weight: 0 };
		this.lists.totalCount = lists.totalItems;
		this.listTags = [...tags, { ...dummyList }];
		if (type === 'private') {
			lists.privateLists = this.addDummyList(lists.privateLists, dummyList);
			this.privateLists = [...lists.privateLists];
		} else {
			lists.publicLists = this.addDummyList(lists.publicLists, dummyList);
			this.publicLists = [...lists.publicLists];
		}
	}

	public getListsWithTags() {
		const page = this.pager.currentPage || 1;
		const searchTerm = this.trimWhiteSpaces(this.publicSearchTerm);
		zip(
			this.publicContentService.getLists(page, 'public', searchTerm),
			this.publicContentService.getTags()
		).subscribe(
			([lists, tags]) => {
				this.handleListsResponse(lists, tags, 'public');
				this.addListsToForm();
				this.addListType();
				this.setPagination();
			},
			err => {
				this.toastr.error(
					'We are unable to process your request at this moment, try after sometime'
				);
			}
		);
	}

	resetSearch() {
		this.privateSearchTerm = '';
		this.publicSearchTerm = '';
		this.searchForm.reset();
	}

	resetPage() {
		this.pager.currentPage = 1;
	}

	public clearSearchResults() {
		if (!this.searchForm.value.search) {
			this.searchLists();
		}
	}

	getPublicLists() {
		this.resetSearch();
		if (this.privatePage) {
			this.resetPage();
			this.privatePage = false;
		}
		this.getListsWithTags();
	}

	getPrivateLists() {
		this.resetSearch();
		if (!this.privatePage) {
			this.resetPage();
			this.privatePage = true;
		}
		this.getPrivateListsWithTags();
	}

	public searchLists() {
		this.resetPage();
		if (this.privatePage) {
			this.privateSearchTerm = this.searchForm.value.search;
			this.getPrivateListsWithTags();
		} else {
			this.publicSearchTerm = this.searchForm.value.search;
			this.getListsWithTags();
		}
	}

	public getPrivateListsWithTags() {
		const page = this.pager.currentPage || 1;
		const searchTerm = this.trimWhiteSpaces(this.privateSearchTerm);
		zip(
			this.publicContentService.getLists(page, 'private', searchTerm),
			this.publicContentService.getTags()
		).subscribe(
			([lists, tags]) => {
				this.handleListsResponse(lists, tags, 'private');
				this.addListsToForm();
				this.addListType();
				this.setPagination();
			},
			err => {
				this.toastr.error(
					'We are unable to process your request at this moment, try after sometime'
				);
			}
		);
	}

	private addDummyList(lists: any[], dummyList: any): any[] {
		return lists.map(list => {
			if (isEmpty(list?.tags)) {
				list.tags = [{ ...dummyList }];
			}
			list['isDisabled'] = false;
			return list;
		});
	}

	private addListsToForm() {
		this.publicLists = this.getListWithTagsMapping(this.publicLists);
		this.privateLists = this.getListWithTagsMapping(this.privateLists);
		this.publicLists.forEach(list => {
			this.signupForm.addControl(list.list_id, new UntypedFormControl(false));
		});
		this.privateLists.forEach(list => {
			this.signupForm.addControl(list.list_id, new UntypedFormControl(false));
		});
	}

	private getListWithTagsMapping(lists) {
		const mappedLists = [];
		lists.map(list => {
			list.tags.map(tag => {
				const newList = { ...list, tags: [{ ...tag }] };
				newList.list_id = `${newList.list_id}_${tag.id}`;
				mappedLists.push(newList);
			});
		});
		return mappedLists;
	}

	public hideTag(tag, lists) {
		if (!tag || !lists) return '';
		const tagsInList = [
			...new Set(
				lists
					.map(list => list.tags)
					?.flat()
					.map(item => item.id)
			),
		];
		return tagsInList.includes(tag?.id) ? '' : 'hide-tag';
	}

	private addListType() {
		this.privateLists = this.privateLists.map(list => {
			list['listType'] = 'private';
			return list;
		});
		this.publicLists = this.publicLists.map(list => {
			list['listType'] = 'public';
			return list;
		});
	}

	checkValue($event, type) {
		const selectedList = $event.target.value.split('_')[0];
		const lists = type === 'public' ? this.publicLists : this.privateLists;
		const listsWithCommonTags = lists.filter(list => list.list_id.includes(selectedList));
		listsWithCommonTags.forEach(list => {
			if (list.list_id !== $event.target.value) {
				list.isDisabled = $event.currentTarget.checked;
			}
		});
	}

	submitLists() {
		const lists = Object.keys(this.signupForm.value).filter(
			key => this.signupForm.value[key] === true
		);
		if (!lists.length) {
			alert('Please choose a list');
			return;
		}

		this.isLoading = true;

		this.publicContentService.sendLists(this.buildPostRequest(lists)).subscribe(
			res => {
				const navigationExtras: NavigationExtras = {
					state: this.buildSubscriptionList(res.data),
				};
				this.signupForm.reset();
				this.isLoading = false;
				this.router.navigateByUrl('listserv-success', navigationExtras).then(() => {
					window.location.reload();
				});
			},
			err => {
				this.signupForm.reset();
				this.isLoading = false;
				this.toastr.error(
					'We are unable to process your request at this moment, try after sometime'
				);
			}
		);
	}

	buildSubscriptionList(lists) {
		return {
			subscribed: this.groupBy(lists, 'statusText')['Accepted'],
			conflict: this.groupBy(lists, 'statusText')['Conflict'],
		};
	}

	private groupBy(arr, property) {
		return arr.reduce((acc, cur) => {
			[...this.publicLists, ...this.privateLists].forEach(item => {
				const activeListId = item.list_id.split('_')[0];
				if (activeListId === cur.listId) {
					cur['display_name'] = item.display_name;
				}
			});
			acc[cur[property]] = [...(acc[cur[property]] || []), cur];
			return acc;
		}, {});
	}

	private buildPostRequest(lists) {
		lists = lists.map(list => list.split('_')[0]);
		const selectedListTypes = this.createListMap(lists);
		const userDetails = {
			display_name: this.signupForm.value.name,
			subscriber: this.signupForm.value.email,
		};
		return { lists, userDetails, selectedListTypes };
	}

	createListMap(keys) {
		return [...this.publicLists, ...this.privateLists].reduce((listMap, item) => {
			if (keys.includes(item.list_id)) listMap[item.list_id] = item.listType;
			return listMap;
		}, {});
	}

	setPagination() {
		const page = this.pager.currentPage || 1;
		const itemsPerPage = 10;
		const totalPages = Math.ceil(this.lists.totalCount / itemsPerPage);
		if (page < 1 || (this.pager.totalPages !== undefined && page > totalPages)) {
			return;
		}
		this.pager = this.pagerService.getPager(this.lists.totalCount, page, itemsPerPage);
		if (this.privatePage) {
			this.pagedItems = this.privateLists.slice(this.pager.startIndex, this.pager.endIndex + 1);
		} else {
			this.pagedItems = this.publicLists.slice(this.pager.startIndex, this.pager.endIndex + 1);
		}
	}

	/**
	 * This method takes the current page number from UI and lists the items correspondingly.
	 * @param page
	 */
	setPage() {
		if (this.privatePage) {
			this.getPrivateListsWithTags();
		} else {
			this.getListsWithTags();
		}
	}
}
