import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { TermsconditionsComponent } from './termsconditions/termsconditions.component';
import { RedirectComponent } from './redirect/redirect.component';
import { PageComponent } from './public/page/page.component';
import { FilesComponent } from './public/files/files.component';
import { PublicComponent } from './public/public.component';
import { SearchComponent } from './public/search/search/search.component';
import { NewsComponent } from './public/news/news.component';
import { ListserveComponent } from './public/list-serve/listserv.component';
import { ListServSuccessComponent } from './public/list-serve/success/listserv-success.component';
import { HospitalsAtHomeComponent } from './public/hospitals-at-home/waiver/hospitals-at-home.component';
import { HospitalsAtHomeSuccessComponent } from './public/hospitals-at-home/waiver/hospitals-at-home-success/hospitals-at-home-success.component';
import { HospitalsAtHomeMeasureComponent } from './public/hospitals-at-home/measure/hospital-at-home-meaure.component';
import { CallbackComponent } from './callback/callback.component';
import { PublicErrorComponent } from './public/components/error-component/error-component';
import { VaccineAttestationComponent } from './public/vaccine-attestation/vaccine-attestation.component';
import { VmapSuccesComponent } from './public/vaccine-attestation/vmap-succes/vmap-succes.component';
import { EnableFeatureGuardService } from './secure/enable-feature-guard.service';
import { CagAlzheimerComponent } from './public/cag-alzheimer/cag-alzheimer.component';
import { CAGLandingSuccessPageComponent } from './public/cag-alzheimer/cag-landing-page/cag-landing-success-page.component';

const routes: Routes = [
	{
		path: 'callback',
		component: CallbackComponent,
	},
	{ path: 'terms', component: TermsconditionsComponent },
	{ path: 'redirect', component: RedirectComponent },
	{
		path: 'admin',
		loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
	},
	{
		path: '',
		component: PublicComponent,
		children: [
			{ path: 'listserv-signup', component: ListserveComponent },
			{ path: 'listserv-success', component: ListServSuccessComponent },
			{
				path: 'acute-hospital-care-at-home/reporting-measures',
				component: HospitalsAtHomeMeasureComponent,
			},
			{ path: 'acute-hospital-care-at-home/waiver', component: HospitalsAtHomeComponent },
			{
				path: 'acute-hospital-care-at-home/waiver-success',
				component: HospitalsAtHomeSuccessComponent,
			},
			{
				path: 'alzheimers-ced-registry/submission',
				component: CagAlzheimerComponent,
			},
			{
				path: 'alzheimers-ced-registry/submission-success',
				component: CAGLandingSuccessPageComponent,
			},
			{ path: 'files/:id', component: FilesComponent },
			{ path: 'search', component: SearchComponent },
			{ path: 'news', component: NewsComponent },
			{ path: 'previews/:id', component: PageComponent },
			{
				path: 'request-rejected/:id',
				component: PublicErrorComponent,
			},
			{
				path: 'vaccine-requirement-attestation-form',
				component: VaccineAttestationComponent,
				canActivate: [EnableFeatureGuardService],
				data: {
					feature: 'vrap',
				},
			},
			{ path: 'vaccine-requirement-attestation-success', component: VmapSuccesComponent },
			{
				path: '**',
				component: PageComponent,
				pathMatch: 'full',
			},
		],
	},
];

const routerOptions: ExtraOptions = {
	scrollPositionRestoration: 'top',
  };

@NgModule({
	imports: [RouterModule.forRoot(routes, routerOptions)],
	exports: [RouterModule],
})
export class AppRoutingModule {}
